/** @jsx jsx */
import { jsx } from '@emotion/react'
import React from "react"
import Base from "../../components/templates/Base"
import Banner from "../../components/organisms/banner/Banner"
import Breadcrumbs from "../../components/organisms/breadcrumbs/Breadcrumbs"
import { useVendorData } from "../../components/particles/hooks/useVendorData"
import Content from "../../components/particles/Content"
import { css } from '@emotion/react'
import LabelImage from "../../components/molecules/LabelImage/LabelImage"
import { randomID } from "../../components/particles/helpers"
import theme from "../../components/particles/Theme"

const AlignCards = css`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 5rem;
  margin-bottom: 5rem;

  @media (max-width: 1150px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${theme.breakpoints[1]}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${theme.breakpoints[0]}) {
    grid-template-columns: 1fr;

     .gatsby-image-wrapper {
       margin: 0 auto;
     }
  }
`

const VendorPage = () => {
  const vendors = useVendorData()
  const urls = []

  return (
    <Base title="Vendors">
      <Banner title="Vendors" />
      <Breadcrumbs />
      <Content css={AlignCards}>
        {vendors
          .sort((a, b) => (a.node.title > b.node.title ? 1 : -1))
          .map(vendor => {
            if (
              vendor.node.VendorInfo.logo.localFile.childImageSharp &&
              !urls.includes(vendor.node.VendorInfo.logo.sourceUrl)
            ) {
              urls.push(vendor.node.VendorInfo.logo.sourceUrl)
              return (
                <LabelImage
                  key={randomID()}
                  label={vendor.node.title}
                  image={vendor.node.VendorInfo.logo}
                  url={vendor.node.VendorInfo.externalUrl}
                  className="vendor-logo"
                />
              )
            }
            return null
          })}
      </Content>
    </Base>
  )
}

export default VendorPage
